$('[data-fancybox="gallery"]').fancybox({
  hash: false,
});

$(document).ready(function () {
  var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

  var cycle = function (index) {
    var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

    nextIndex = index !== undefined ? index : nextIndex;

    $currentSlide = $($slides.get(currentIndex));
    $currentSelector = $($selectors.get(currentIndex));

    $nextSlide = $($slides.get(nextIndex));
    $nextSelector = $($selectors.get(nextIndex));

    $currentSlide.removeClass("active").css("z-index", "0");

    $nextSlide.addClass("active").css("z-index", "1");

    $currentSelector.removeClass("current");
    $nextSelector.addClass("current");

    currentIndex =
      index !== undefined
        ? nextIndex
        : currentIndex < $slides.length - 1
        ? currentIndex + 1
        : 0;

    nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
  };

  $(function () {
    currentIndex = 0;
    nextIndex = 1;

    $slides = $(".slide");
    $selectors = $(".selector");
    $btns = $(".btn");

    $slides.first().addClass("active");
    $selectors.first().addClass("current");

    interval = window.setInterval(cycle, 4000);

    $selectors.on("click", function (e) {
      var target = $selectors.index(e.target);
      if (target !== currentIndex) {
        window.clearInterval(interval);
        cycle(target);
        interval = window.setInterval(cycle, 4000);
      }
    });

    $btns.on("click", function (e) {
      window.clearInterval(interval);
      if ($(e.target).hasClass("prev")) {
        var target = currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
        cycle(target);
      } else if ($(e.target).hasClass("next")) {
        cycle();
      }
      interval = window.setInterval(cycle, 4000);
    });
  });

  var menu = [];
  $(".swiper-slide").each(function (index) {
    menu.push($(this).find(".slide-inner").attr("data-text"));
  });

  var interleaveOffset = 0.5;
  var swiperOptions = {
    loop: true,
    speed: 1000,
    parallax: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    loopAdditionalSlides: 0,
    watchSlidesProgress: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      progress: function () {
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          var slideProgress = swiper.slides[i].progress;
          var innerOffset = swiper.width * interleaveOffset;
          var innerTranslate = slideProgress * innerOffset;
          swiper.slides[i].querySelector(".slide-inner").style.transform =
            "translate3d(" + innerTranslate + "px, 0, 0)";
        }
      },
      touchStart: function () {
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = "";
        }
      },
      setTransition: function (speed) {
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = speed + "ms";
          swiper.slides[i].querySelector(".slide-inner").style.transition =
            speed + "ms";
        }
      },
    },
  };

  var swiper = new Swiper(".swiper-container", swiperOptions);

  var sliderBgSetting = $(".slide-bg-image");
  sliderBgSetting.each(function (indx) {
    if ($(this).attr("data-background")) {
      $(this).css(
        "background-image",
        "url(" + $(this).data("background") + ")"
      );
    }
  });
});

$(document).ready(function () {
  function scrollToVips() {
    var vipsElement = $(".vips");
    if (vipsElement.length > 0) {
      $("html, body").animate(
        {
          scrollTop: vipsElement.offset().top,
        },
        500
      );
    }
  }
  $("[data-scroll-to]").on("click", function () {
    scrollToVips();
  });
});

$(document).ready(function () {
  const mediaQuery = window.matchMedia("(min-width: 600px)");

  if (mediaQuery.matches) {
    const $fadersLeft = $(".fade-left");
    const $fadersRight = $(".fade-right");

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -50px 0px",
    };

    const appearOnScroll = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          $(entry.target).addClass("show");
        } else {
          $(entry.target).removeClass("show");
        }
      });
    }, appearOptions);

    $fadersLeft.each(function () {
      appearOnScroll.observe(this);
    });

    $fadersRight.each(function () {
      appearOnScroll.observe(this);
    });
  } else {
  }
});
